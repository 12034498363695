import './App.scss';
import Partner from './components/Partner';
import AccommodationList from './components/AccommodationList';
import Content from './components/Content';
import Separator from './components/Separator';
import Footer from './components/Footer';
import ferme1 from './assets/cour.jfif';
import Modal from 'react-modal';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-mdi/window-close';
import MyForm from './components/Form/Form';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';

const ids = {
  '6d65616c': 'meal',
  7061727479: 'party',
};

const accommodations = [
  {
    name: 'Lomalienne',
    info: ['Rue de Ligney 27(A)', '4252 Geer'],
    url: 'https://www.booking.com/Share-ETz3JmR',
    capacity: '6',
    capacity2: '6: 3 lits',
  },
  {
    name: 'Centre Au Chardon',
    info: ['Rue du chardon 10', '4260 Fallais'],
    url: 'https://www.booking.com/Share-53A6DsE',
    capacity: '4',
    capacity2: '4: 2 lits',
  },
  {
    name: 'B&B Le Bois de Champia',
    info: ['Rue Theys 1A', ' 4520 Huy'],
    url: 'https://www.booking.com/Share-5pkcnn',
    capacity: '6',
    capacity2: '6: 3 lits',
  },
  {
    name: 'Moulin De Moxhe bed&dreams',
    info: ['Sentier De La Meunerie 58', '4280 Moxhe'],
    url: 'https://www.booking.com/Share-XaIsMR',
    capacity: '6',
    capacity2: '6: 2 lits + canapé',
  },
  /* {
    name: "Hotel Vorsen",
    info: ["Hoogstraat 32", "3890 Gingelom"],
    url: "https://www.booking.com/Share-5mRIWp"
  },*/ // 19min
  {
    name: 'Au Calme de Hosdent',
    info: ['Rue Du Moulin 32', '4261 Braives'],
    url: 'https://www.booking.com/Share-nqqZc3',
    capacity: '7',
    capacity2: '7: 4 lits',
  },
  /*{
    name: "De Smishoeve",
    info: ["Smisstraat 6", "3870 Heers"],
    url: "https://be.hotels.com/ho710022976/de-smishoeve-heers-belgique/?chkin=2023-08-12&chkout=2023-08-13&destType=MARKET&destination=Braives%2C%20R%C3%A9gion%20de%20la%20Wallonie%2C%20Belgique&expediaPropertyId=22156968&locale=fr_BE&pwa_ts=1668223828254&referrerUrl=aHR0cHM6Ly9iZS5ob3RlbHMuY29tL0hvdGVsLVNlYXJjaA%3D%3D&regionId=3000044489&rfrr=HSR&rm1=a2&selectedRatePlan=282174570&selectedRoomType=219338048&sort=RECOMMENDED&top_cur=EUR&top_dp=102&useRewards=false&userIntent=&x_pwa=1"
  },*/ // 23min
  {
    name: 'Park Inn by Radisson Liege Airport',
    info: ["Rue de l'Aeroport 14", 'Batiment 14', '4460 Grace-Hollogne'],
    url: 'https://be.hotels.com/ho253131/park-inn-by-radisson-liege-airport-grace-hollogne-belgique/?chkin=2023-08-12&chkout=2023-08-13&x_pwa=1&rfrr=HSR&pwa_ts=1668223828335&referrerUrl=aHR0cHM6Ly9iZS5ob3RlbHMuY29tL0hvdGVsLVNlYXJjaA%3D%3D&useRewards=false&rm1=a2&regionId=3000044489&destination=Braives%2C%20R%C3%A9gion%20de%20la%20Wallonie%2C%20Belgique&destType=MARKET&sort=RECOMMENDED&top_dp=94&top_cur=EUR&userIntent=&selectedRoomType=314068095&selectedRatePlan=381873036&expediaPropertyId=1631420',
    capacity: '100',
    capacity2: '100 chambres',
  },
  /* {
    name: "Château de la Motte",
    info: ["Mettekovenstraat 4", "3800 Groot-Gelmen, Sint-Truiden"],
    url: "https://be.hotels.com/ho441395/chateau-de-la-motte-saint-trond-belgique/?chkin=2023-08-12&chkout=2023-08-13&x_pwa=1&rfrr=HSR&pwa_ts=1668223828258&referrerUrl=aHR0cHM6Ly9iZS5ob3RlbHMuY29tL0hvdGVsLVNlYXJjaA%3D%3D&useRewards=false&rm1=a2&regionId=3000044489&destination=Braives%2C%20R%C3%A9gion%20de%20la%20Wallonie%2C%20Belgique&destType=MARKET&sort=RECOMMENDED&top_dp=100&top_cur=EUR&userIntent=&selectedRoomType=200357970&selectedRatePlan=202190909&expediaPropertyId=4564698"
  },*/ // 27min
  /*{
    name: 'Kamerijck Bed & Breakfast',
    info: ['Kamerijckstraat 11', '3890 Gingelom'],
    url: 'https://be.hotels.com/ho372924/kamerijck-bed-breakfast-gingelom-belgique/?chkin=2023-08-12&chkout=2023-08-13&x_pwa=1&rfrr=HSR&pwa_ts=1668223828253&referrerUrl=aHR0cHM6Ly9iZS5ob3RlbHMuY29tL0hvdGVsLVNlYXJjaA%3D%3D&useRewards=false&rm1=a2&regionId=3000044489&destination=Braives%2C%20R%C3%A9gion%20de%20la%20Wallonie%2C%20Belgique&destType=MARKET&sort=RECOMMENDED&top_dp=120&top_cur=EUR&userIntent=&selectedRoomType=200424616&selectedRatePlan=202433350&expediaPropertyId=4255631',
    capacity: '6',
    capacity2: '6 chambres',
  },*/ // complet
  {
    name: 'Studio Chez Michael',
    info: ['Rue du parc', '4300 Waremme'],
    url: 'https://fr.airbnb.be/rooms/51147318',
    capacity: '3',
    capacity2: '3: 1 lit + canapé',
  },
  {
    name: 'Maisonnette Chez Eddy',
    info: ['Rue Eugène Quique', '4520 Wanze'],
    url: 'https://fr.airbnb.be/rooms/673672617307062107',
    capacity: '4',
    capacity2: '4: 1 lit  + 1 canapé (+baby)',
  },
  {
    name: 'Lachambre 2 Marneffe',
    info: ['Rue Hébret', '4210 Burdinne'],
    url: 'https://fr.airbnb.be/rooms/52356557',
    capacity: '2',
    capacity2: '2: 1 lit king size',
  },
];

const partners = [
  {
    name: 'Traiteur',
    info: ['Quent on a faim', '4 Rue Dieudonné Lefèvre', '1020 Bruxelles'],
    url: 'https://quentonafaim.be/',
  },
  {
    name: 'DJ',
    info: ['Last Night', 'Maverick Ierset'],
    url: 'https://lastnight.be',
  },
  {
    name: 'Fleuriste',
    info: ['Coloris-Fleurs','Rue Neuve 23', '4500 Huy'],
  },
  {
    name: 'Robe',
    info: ['Manice','Chaussée de Charleroi, 53A', '1360 Thorembais-Saint-Trond'],
    url: 'https://www.manice.be/',
  },
  {
    name: 'Costume',
    info: ['Mister Costumes','Chau. de Louvain 478', '5004 Namur'],
    url: 'https://www.mistercostumes.be/',
  },
];

Modal.setAppElement('body');

function App() {
  const [searchParams] = useSearchParams();
  const [modalIsOpen, setIsOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  // This holds the information about dark mode/light mode
  const [mode, setMode] = useState();

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        const colorScheme = event.matches ? 'dark' : 'light';
        setMode(colorScheme);
      });
  }, []);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setMode('dark');
    }
  }, []);

  const { t } = useTranslation();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const modalInset = window.innerWidth < 600 ? '0' : '40px';

  let formType = null;
  const hashA = [...searchParams.entries()];

  if (hashA.length) {
    const hash = hashA[0][0];
    formType = Object.keys(ids).includes(hash) ? ids[hash] : 'party3';
  } else formType = 'party2';

  let textArray = ['nlbr', t('intro'), 'nlbr', t('intro3'), 'nlbr'];
  textArray.push(
    t('intro4'),
    'nlbr',
    t('intro5'),
    t('intro6'),
    'nlbr',
    t('intro7'),
    'nlbr',
    t('intro8'),
  );

  textArray = [...textArray, 'nlbr', 'Mathilde & Cédric'];

  return (
    <div id="main" className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div className="name">
          Mathilde {/* <img width="40" alt="" src={rings}></img> */}
          <span className="nameLink">&</span> Cédric
        </div>
        <div>{t('Date')}</div>
        <LanguageSwitcher></LanguageSwitcher>
      </header>
      {/* <div className="debug">
      <p>{`${formType} => ${formType}`}</p>
    </div> */}
      <Modal
        isOpen={modalIsOpen}
        className={formType !== 'meal' ? 'party' : 'meal'}
        contentLabel="Form"
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        style={{
          content: {
            top: modalInset,
            left: modalInset,
            right: modalInset,
            bottom: modalInset,
            backgroundColor: '#fff', //mode === "dark" ? "#111114" : "#fff"
          },
        }}
      >
        <div className="scroll-component">
          <div className="scroll-content">
            <div className="header">
              <h1>
                {t('formTitle')} {formType !== 'meal' ? t('party') : t('meal')}
              </h1>
              <Icon
                icon={closeIcon}
                onClick={closeModal}
                className="close-icon"
              />
            </div>
            <MyForm formType={formType}></MyForm>
          </div>
        </div>
      </Modal>

      <Content
        keyIndex="text1"
        description={true}
        last
        text={textArray}
      ></Content>
      {/* <div className="rsvp submit" onClick={openModal}>
        RSVP
      </div> */}
      <Separator />
      {/* {formType !== 'meal' && (
        <div className="party-text">{t('partyText')}</div>
      )}
      {formType === 'meal' && (
        <>
          <h2 className="planning-title">{t('planning')}</h2>
          <div className="ceremony-drink">
            <Content
              keyIndex="planningText"
              description={true}
              align="planning ceremony"
              text={[t('ceremony'), '14h30']}
            ></Content>
            <Content
              keyIndex="planningText"
              description={true}
              align={`planning drink ${
                i18next.language === 'en' ? 'nomargin' : ''
              }`}
              text={[t('drink'), '16h']}
            ></Content>
          </div>
          <div className="meal-party">
            <Content
              keyIndex="planningText2"
              description={true}
              align={`planning meal ${
                i18next.language === 'en' ? 'nomargin' : ''
              }`}
              last
              text={[t('planningMeal'), '18h']}
            ></Content>
            <Content
              keyIndex="planningText2"
              description={true}
              align="planning party"
              last
              text={[t('planningParty'), '21h30']}
            ></Content>
          </div>
        </>
      )}
      <Separator /> */}
      <Partner
        className="location"
        keyIndex="location"
        title={t('Location')}
        name="La Ferme des Capucines"
        info={['Rue du Centre 73', '4261 Braives']}
        pictures={[ferme1]}
        //align="left"
        showMap={true}
      ></Partner>
      <Separator />
      <Content
        keyIndex="giftsText"
        description={true}
        last
        text={[t('gifts'), t('gifts2'), t('gifts3')]}
      ></Content>
      <Separator></Separator>
      {/*<h2 className="accommodation-title">{t('Accommodations')}</h2>
      <Content
        keyIndex="accomodationText"
        description={true}
        className="content accommodation-text"
        last
        text={[t('AccomodationText'), t('AccomodationText2')]}
      ></Content>

       <center>
        <img alt="Bank" src={bank} width="100" height="100"></img>
      </center> 

      <AccommodationList
        //title={t('Accommodations')}
        list={accommodations}
        filtered={false}
      ></AccommodationList>*/}
      <AccommodationList
        title={t('Partners')}
        list={partners}
        filtered={true}
        description={true}
      ></AccommodationList>
      <Footer />
    </div>
  );
}

export default App;
