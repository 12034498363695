import React from 'react';
import MyMap from './Map/Map';
import Content from './Content';
const location = {
  address: ['La Ferme des Capucines', 'Rue du Centre 73', '4261 Braives'],
  lat: 50.624633877604126,
  lng: 5.162946705955793,
};

class Partner extends React.Component {
  render() {
    return (
      <>
        <h2 className="partner-title">{this.props.title}</h2>

        <div className={`partner${this.props.showMap ? ' col map' : ''}`}>
          <Content
            keyIndex={this.props.keyIndex}
            title={this.props.name}
            text={this.props.info}
            align={this.props.align}
            last
            address={true}
          ></Content>

          {this.props.showMap && <MyMap location={location} zoomLevel={16} />}
        </div>
        {this.props.pictures && (
          <div className="pictures">
            {this.props.pictures.map((t, index) => (
              <img key={index} alt="" src={t}></img>
            ))}
          </div>
        )}
      </>
    );
  }
}
export default Partner;
