import React from 'react';
import Content from './Content';

class Accommodation extends React.Component {
  render() {
    return (
      <div className="accommodation">
        <Content
          keyIndex={`${this.props.keyIndex}-content`}
          title={this.props.name}
          text={this.props.info}
          url={this.props.url}
          align={this.props.align}
          address={this.props.description ? false : true}
          description={this.props.description}
          tooltip={this.props.capacity}
        ></Content>
      </div>
    );
  }
}
export default Accommodation;
