import React from 'react';
import { Icon } from '@iconify/react';
import copyrightIcon from '@iconify/icons-mdi/copyright';
class Footer extends React.Component {
  render() {
    let count = 0;
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function easter(e) {
      count++;
      if (count >= 7) {
        console.log('[DBG] surprise', e);
        e.target.className = 'text-blur-out';
        document.getElementById('copyrightIcon').className = 'vibrate-3';
        //document.getElementById('mains').className += ' active';

        await delay(2000);
        document.getElementById('patrick').className += ' active';
        document.getElementById('copyrightIcon').className = 'text-blur-out';
        await delay(2000);
        document.getElementById('copyrightIcon').className = '';
        e.target.className = '';
        await delay(2000);
        document.getElementById('coucou').className += ' active';
        count = 0;
        window.location.reload();
      }
    }
    return (
      <>
        <div className="footer">
          <span id="copyrightIcon">
            <Icon icon={copyrightIcon} />
          </span>
          <span onClick={window.innerWidth < 500 ? easter : null}>
            euphory 2023
          </span>
          {/* <span>euphory 2023</span> */}
        </div>
        <div id="patrick" className="patrick"></div>
        <div id="coucou" className="coucou"></div>
      </>
    );
  }
}
export default Footer;
