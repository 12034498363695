import React from 'react';
import Separator from './Separator';
import { useTranslation } from 'react-i18next';

function Content(props) {
  const classN = props.last ? ' last' : '';

  console.log(props);

  const columns = props.columns ? props.columns : 1;
  const texts = Object.values(props.text);
  const { t } = useTranslation();
  return (
    <div
      key={props.keyIndex}
      className={`${props.className ? props.className : 'content'}${classN} ${
        props.align ? props.align : ''
      }`}
    >
      {' '}
      {props.title && <h2 className="content-title">{props.title}</h2>}
      <div
        className={`content-container${classN} ${
          props.align ? props.align : ''
        }`}
      >
        {props.address && (
          <div className="address">
            {' '}
            {props.text[0]}
            <br />
            {props.text[1]}
          </div>
        )}
        {props.tooltip && (
          <span className="tooltip">{`${props.tooltip}p`}</span>
        )}
        {props.description &&
          columns > 1 &&
          Object.values(props.text)
            .filter(t => t.length > 0 || t === 'nlbr')
            .map(line => (
              <div>
                {Array.from(line).map((t, index) => (
                  <div
                    className={`description-${props.keyIndex}-${index}`}
                    key={`description-${props.keyIndex}-${index}`}
                  >
                    {t === 'nlbr' ? '' : t}
                    <br />
                  </div>
                ))}
              </div>
            ))}
        {props.description &&
          columns === 1 &&
          texts
            .filter(t => t.length > 0 || t === 'nlbr')
            .map((t, index) => (
              <div
                className={`description-${props.keyIndex}-${index}`}
                key={`description-${props.keyIndex}-${index}`}
              >
                {t === 'nlbr' ? '' : t}
                <br />
              </div>
            ))}
        {!props.address && !props.description && props.text}
        {props.url && (
          <div className="submit-container">
            <a className="submit" target="blank" href={props.url}>
              {t('Link')}
            </a>
          </div>
        )}
      </div>
      {!props.last && <Separator />}
    </div>
  );
}
export default Content;
