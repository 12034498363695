import React from 'react';
import Accommodation from './Accommodation';

class AccommodationList extends React.Component {
  render() {
    const today = new Date();
    const weddingDay = new Date('08/12/2023');
    if (
      !this.props.filtered ||
      (this.props.filtered && today.getTime() >= weddingDay.getTime())
    )
      return (
        <>
          {this.props.title && (
            <h2 className="accommodation-title">{this.props.title}</h2>
          )}
          <div className="accommodation-list">
            {this.props.list.map((item, key) => (
              <Accommodation
                key={`accommodation-${this.props.title}-${key}`}
                keyIndex={`accommodation-${this.props.title}-${key}`}
                debug={`debug${key}`}
                name={item.name}
                info={item.info}
                url={item.url}
                align="left"
                description={this.props.description}
                capacity={item.capacity}
              ></Accommodation>
            ))}
          </div>
        </>
      );
  }
}
export default AccommodationList;
