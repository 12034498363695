import { React, useState } from 'react';
import { useFormik, FormikProvider, Form, useField } from 'formik';
import './styles.scss';
import * as Yup from 'yup';
import Toggle from './Toggle/Toggle';
import axios from 'axios';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

//const sleep = ms => new Promise(r => setTimeout(r, ms));

const TextInputLiveFeedback = ({ label, helpText, ...props }) => {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const handleFocus = () => setDidFocus(true);

  let { required } = props;

  required = required === undefined ? true : required;
  const showFeedback =
    required &&
    ((!!didFocus && field.value.toString().trim().length > 2) || meta.touched);

  return (
    <div
      className={`form-control ${props.className ? props.className : ''}${
        showFeedback ? (meta.error ? ' invalid' : ' valid') : ''
      }`}
    >
      <div className="flex items-center space-between">
        <label htmlFor={props.id}>{label}</label>{' '}
      </div>
      <input
        {...props}
        {...field}
        aria-describedby={`${props.id}-feedback ${props.id}-help`}
        onFocus={handleFocus}
        className={meta.error ? 'bounce' : ''}
      />
      <div className="text-xs" id={`${props.id}-help`} tabIndex="-1">
        {helpText}
      </div>
      {showFeedback ? (
        <div
          id={`${props.id}-feedback`}
          aria-live="polite"
          className="feedback text-sm"
        >
          {meta.error ? meta.error : ''} {/*"✓"*/}
        </div>
      ) : null}
    </div>
  );
};

const getSubmitHandler =
  ({ attend }) =>
  (values, formik) => {
    values.attend = attend;
    //if (!attend)
    formik.submitForm();
  };

const MyFormInternal = ({ ...props }) => {
  const {
    isActive,
    handleDisplay,
    formType,
    handleSubmit,
    isSubmitting,
    handleSubmitting,
    setResultFirstName,
  } = props;
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      attendees: '',
      children: '',
      special: '',
      dance: '',
      attend: !isActive,
      language: i18next.language,
      type: formType,
      date: Date.parse(new Date()),
    },
    onSubmit: async (values, { resetForm, initialValues }) => {
      handleSubmitting(true);

      axios({
        method: 'post',
        url: `${window.location.origin}/61339fb7-8a9f-4037-873b-f947bb849726/`,
        headers: { 'content-type': 'application/json' },
        data: values,
      })
        .then(result => {
          handleSubmit(true);
          handleSubmitting(false);
          setResultFirstName(' ' + result.data.firstname);
        })
        .catch(error => console.log('error', error));

      Object.keys(values).forEach(key => (values[key] = ''));
      resetForm(values);
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .min(2, t('min2char'))
        .required(t('required').replace('{0}', t('First Name')))
        .matches(/^[a-zàâçéèêëîïôûùüÿñæœ -]+$/i, t('alphOnly')),
      lastname: Yup.string()
        .min(2, t('min2char'))
        .required(t('required').replace('{0}', t('Last Name')))
        .matches(/^[a-zàâçéèêëîïôûùüÿñæœ -]+$/i, t('alphOnly')),
      email: Yup.string()
        .required(t('required').replace('{0}', t('Email')))
        .email(t('validEmail')),
      mobile: Yup.string()
        .notRequired()
        .matches(/(^\+*?[0-9]{9,15}$)/, t('validMobilePhone')),
      attendees: Yup.mixed().when('attend', {
        is: true,
        then: Yup.string()
          .matches(/^(\d)$/, t('between1_9'))
          .min(1, t('min1digit'))
          .max(9, t('less2digits'))
          .required(t('required').replace('{0}', t('Attendees'))),
      }),
      children: Yup.string()
        .matches(/^(\d)$/, t('between1_9'))
        .min(1, t('min1digit'))
        .max(1, t('less2digits'))
        .notRequired(),
    }),
  });

  return (
    <FormikProvider value={formik}>
      <Form className="form">
        <div className="conditionalContainer">
          <div className="names">
            <TextInputLiveFeedback
              label={t('First Name')}
              id="firstname"
              name="firstname"
              type="text"
            />
            <TextInputLiveFeedback
              label={t('Last Name')}
              id="lastname"
              name="lastname"
              type="text"
            />
          </div>
          <TextInputLiveFeedback
            label={t('Email')}
            id="email"
            name="email"
            type="email"
            //required={false}
          />
          <TextInputLiveFeedback
            label={t('Mobile')}
            id="mobile"
            name="mobile"
            helpText="0471 11 22 33"
            type="text"
          />
          <Toggle
            isChecked={isActive}
            handleDisplay={handleDisplay}
            form={formik}
          ></Toggle>
          {isActive && (
            <div>
              <div className="attendees">
                <TextInputLiveFeedback
                  label={t('Attendees')}
                  id="attendees"
                  name="attendees"
                  helpText={t('1-9')}
                  type="number"
                  min="1"
                  max="9"
                />
                {formType === 'meal' && (
                  <TextInputLiveFeedback
                    label={t('Children')}
                    id="children"
                    name="children"
                    // helpText={t("1-9")}
                    type="number"
                    min="1"
                    max="9"
                    className="children"
                  />
                )}
              </div>
              {formType === 'meal' && (
                <TextInputLiveFeedback
                  label={t('Special')}
                  id="special"
                  name="special"
                  type="text"
                />
              )}

              <TextInputLiveFeedback
                label={t('Dance')}
                id="dance"
                name="dance"
                type="text"
              />
            </div>
          )}
        </div>
        {isActive && (
          <div
            className="submit"
            onClick={event =>
              getSubmitHandler({ attend: true })(formik.values, formik)
            }
          >
            {t('Submit')}
            {isSubmitting ? (
              <Icon
                className="progress"
                icon="line-md:loading-twotone-loop"
                width="36"
              />
            ) : null}
          </div>
        )}
        {!isActive && (
          <div
            className="submit"
            onClick={event =>
              getSubmitHandler({ attend: false })(formik.values, formik)
            }
          >
            {t('Submit')}
            {/*isSubmitting ? <Spinner color="#fff" size={24} /> : null*/}
            {isSubmitting ? (
              <Icon
                className="progress"
                icon="line-md:loading-twotone-loop"
                width="36"
              />
            ) : null}
          </div>
        )}
        {/*<div>
           <div className="buttons">
            <div className="submit submit2">{t("Submit")}</div>
            <a href="https://www.google.be" className="btn btn-2">
              {t("Submit")}
            </a>
            <a href="https://www.google.be" className="btn3">
              {t("Submit")}
            </a>
            <a href="https://www.google.be" className="btn4">
              {t("Submit")}
            </a>
          </div> 
        </div>*/}
      </Form>
    </FormikProvider>
  );
};

const MyForm = props => {
  const [isActive, setIsActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resultFirstName, setResultFirstName] = useState('toto');
  const { t } = useTranslation();

  return (
    (!isSubmitted && (
      <div>
        <MyFormInternal
          formType={props.formType}
          isActive={isActive}
          handleDisplay={setIsActive}
          isSubmitting={isSubmitting}
          handleSubmitting={setIsSubmitting}
          isSubmitted={isSubmitted}
          handleSubmit={setIsSubmitted}
          setResultFirstName={setResultFirstName}
        ></MyFormInternal>
      </div>
    )) ||
    (isSubmitted && (
      <div className="response">
        {t('Success')}
        <br />
        <br />
        {t('ThankYou').replace('{FirstName}', resultFirstName)}.<br />
        <br />
        {t('SuccessSeeYouSoon')}
        <br />
        <br />
      </div>
    ))
  );
};
export default MyForm;
