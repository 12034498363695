import React from "react";
import i18next from "i18next";
import { withTranslation } from "react-i18next";
class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
  }
  changeLanguage = lng => {
    i18next.changeLanguage(lng);
  };
  render() {
    return (
      <div className="langSwitcher">
        <span
          className={i18next.language === "fr" ? "active" : ""}
          onClick={() => this.changeLanguage("fr")}
        >
          FR
        </span>
        <span
          className={i18next.language === "en" ? "active" : ""}
          onClick={() => this.changeLanguage("en")}
        >
          EN
        </span>
      </div>
    );
  }
}
export default withTranslation()(LanguageSwitcher);
