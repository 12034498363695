import React from "react";
import "./styles.scss";
import { withTranslation } from "react-i18next";
class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked || false
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange() {
    this.setState({ isChecked: !this.state.isChecked });
    this.props.handleDisplay(!this.state.isChecked);
    //this.props.form.resetForm(this.props.form.initialValues);
  }

  render() {
    return (
      <label className="switch">
        <input
          type="checkbox"
          value={this.state.isChecked}
          onChange={this.handleChange}
        />
        <div className="slider"></div>
        <span className="text">{this.props.t("I will attend")}</span>
      </label>
    );
  }
}
export default withTranslation()(Toggle);
