import React, { Component } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  height: '30vh',
  minWidth: '320px',
};
class MyMap extends Component {
  render() {
    return (
      <LoadScript googleMapsApiKey="AIzaSyBK_o9_2tAtbPAWkxeFLtY0PC4NNY8uFc8">
        {/*"AIzaSyC5Xeouj9CJNydLyB3Z9hcxUKuHXepenQ8">*/}
        <GoogleMap
          clickableIcons={false}
          mapContainerStyle={containerStyle}
          center={this.props.location}
          zoom={this.props.zoomLevel}
        >
          <Marker
            position={this.props.location}
            clickable={false}
            cursor={null}
          />
        </GoogleMap>
      </LoadScript>
    );
  }
}

export default MyMap;
