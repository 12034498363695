import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      Date: '12 August 2023',
      introHello: 'Hello,',
      intro:
        'After more than 7 years of happiness and laughter, and more than 6 years of living together, our relationship is still as deep and intense.',
      intro2: '',
      intro3:
        'We love and respect each other. We support each other at every step on the path of life.',
      intro4:
        'Here we are at a crossroads where we wish to have all our loved ones, family, friends and colleagues around us to shout out to the world our love and our desire to bond even more intimately.',
      intro5: '',
      intro6:
        "Mathilde received a nice little puzzle. She hastened to solve it to discover, with a smile on her face, Cédric's proposal, who was overjoyed when she said yes.",
      intro7:
        'We would be delighted to have you by our side during our wedding celebration. I mean behind us, we will be the stars of the day.',
      intro8: 'We will be thrilled to party with all of you.',
      partyText: 'Party starts at 9:30pm',
      introSeeYouSoon: 'See you soon.',
      gifts:
        'Your presence and your cheerfulness will be the most beautiful present. If you want to give us a gift, an urn will be available.',
      gifts2: 'The ecological option would even be more appreciated.',
      gifts3: 'CLARINVAL-MASSON : BE71001882311369',
      ceremony: 'Ceremony',
      drink: 'Drink',
      planning: 'Planning',
      planningMeal: 'Dinner',
      planningParty: 'Party',
      Location: 'Location',
      Link: 'Link',
      AccomodationText:
        "If you want to stay in the surroundings, you will find a list of accommodation below. Don't delay as availability is limited.",
      AccomodationText2:
        "If you're looking for a place to pitch your tent, the moles will be happy to share our garden.",
      Accommodations: 'Accommodations',
      Partners: 'Providers',
      formTitle: 'Answer for ',
      party: "our wedding's party",
      meal: 'our wedding',
      'I will attend': 'I will be there',
      Submit: 'Submit',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Email: 'Email',
      Mobile: 'Mobile Phone number',
      Attendees: 'Attendee(s)',
      Children: 'Children',
      Special: 'Allergenes, Halal, Vegan...',
      Dance: 'I promise to dance if you play',
      min2char: 'Must be at least 2 characters',
      required: '{0} is required',
      alphOnly: 'Cannot contain special characters or numbers',
      validEmail: 'Must be a valid email',
      validMobilePhone: 'Must be a valid mobile phone number',
      '1-9': '1 - 9',
      between1_9: 'Must be between 1 and 9',
      min1digit: 'Must be at least 1 digit',
      less2digits: 'Must be less than 2 digits',
      Success: 'Response successfully received',
      ThankYou: 'Thank you {FirstName}',
      SuccessSeeYouSoon: 'See you soon',
    },
  },
  fr: {
    translation: {
      Date: '12 août 2023',
      introHello: 'Bonjour, Bonsoir',
      intro:
        'Après plus de 7 ans de bonheur et de rires, et plus de 6 années de vie commune, notre relation est toujours aussi profonde et intense.',
      intro2: '',
      intro3:
        'Nous nous aimons, nous respectons, nous soutenons à chaque instant sur le chemin de la vie.',
      intro4:
        'Nous voici à un croisement auquel nous souhaitons avoir tous nos proches, famille, amis et collègues autour de nous pour crier au monde notre amour et notre désir de nous unir encore plus intimement.',
      intro5: '',
      intro6:
        "Mathilde reçut un joli petit casse-tête qu'elle s'empressa de résoudre pour découvrir, le sourire aux lèvres, la demande de Cédric qui fût au comble de la joie lorsqu'elle accepta.",
      intro7:
        "C'est avec beaucoup d'émotions que nous serions ravi de vous avoir à nos côtés lors de notre célébration de mariage. Enfin derrière, nous serons les stars ce jour-là.",
      intro8: 'On se réjouit de faire la fête. Allez viens, on est bien.',
      partyText: 'La fête débutera à 21h30',
      introSeeYouSoon: 'Allez viens, on est bien.',
      gifts:
        'Votre présence et votre bonne humeur seront les plus beaux présents. Si vous tenez à nous faire un cadeau, une urne sera disponible.',
      gifts2: " L'option écologique serait encore plus appreciée.",
      gifts3: 'CLARINVAL-MASSON : BE71001882311369',
      ceremony: 'Cérémonie',
      drink: "Vin d'honneur",
      planning: 'Programme des festivités',
      planningMeal: 'Repas',
      planningParty: 'Soirée',
      Location: 'Le lieu',
      Link: 'Lien',
      AccomodationText:
        "Si vous désirez loger dans les alentours, vous trouverez une liste d'hébergements plus bas. Ne tardez pas car les disponibilités sont réduites.",
      AccomodationText2:
        'Si vous préférez planter votre tente, les taupes seront ravies de partager notre jardin.',
      Accommodations: 'Hébergements',
      Partners: 'Prestataires',
      formTitle: 'Réponse pour ',
      party: 'la soirée du mariage',
      meal: 'le mariage',
      'I will attend': 'Je serai là',
      Submit: 'Envoyer',
      'First Name': 'Prénom',
      'Last Name': 'Nom',
      Email: 'Email',
      Mobile: 'GSM',
      Attendees: 'Participant(s)',
      Children: 'dont enfant(s)',
      Special: 'Allergènes, Halal, Vegan...',
      Dance: 'Je promets de danser si vous passez',
      min2char: 'Au moins 2 caractères',
      required: '{0} est obligatoire',
      alphOnly: 'Ne peut pas contenir de caractères spéciaux ou des chiffres',
      validEmail: 'Doit être une adresse e-mail valide',
      validMobilePhone: 'Doit être un téléphone mobile valide',
      '1-9': '1 - 9',
      between1_9: 'Compris entre 1 et 9',
      min1digit: 'Au moins 1 chiffre',
      less2digits: 'Maximum 9',
      Success: 'Réponse reçue avec succès',
      ThankYou: 'Merci {FirstName}',
      SuccessSeeYouSoon: 'A très bientôt',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'fr',
});

const languageDetector = new LanguageDetector(i18n.services);

const lngs = languageDetector.detect();

let lng = 'fr';
if (Array.isArray(lngs)) {
  lng = lngs.shift().toLowerCase();
}

const availableLangs = Object.keys(resources);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: lng,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'fr',
  });

if (availableLangs.indexOf(lng) < 0) {
  i18n.changeLanguage('fr');
}

export default i18n;
